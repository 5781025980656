import { render, staticRenderFns } from "./addEditTax.vue?vue&type=template&id=704c2b5b&scoped=true&"
import script from "./addEditTax.vue?vue&type=script&lang=js&"
export * from "./addEditTax.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704c2b5b",
  null
  
)

export default component.exports