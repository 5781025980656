<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px;max-height: 85%;">
    <div class="relative bg-white">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          {{taxId > 0 ? 'Update Tax' : 'Add New Tax'}}
        </div>
        <div v-if="taxId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirmCheck">
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-4">
          <div v-for="(tax, i) in taxlist" :key="i" class=" text-text2">
            <div class="mb-4">
              <TextField
              :inputId="i + 'testInput'"
              :textMaxlength="50"
              :showcharLimit="true"
              :fieldError="tax.nameErr !== ''"
              :inputext="tax.taxName"
              :placholderText="`Tax Name`"
              :lableText="'Tax Name'"
              :autoFocus="false"
              @keyPressAction="tax.nameErr = ''"
              @inputChangeAction="(data) => tax.taxName = data"  />
              <p v-if="tax.nameErr !== ''" class="text-error heading-7">{{tax.nameErr}}</p>
            </div>
              <div class="mb-4 flex justify-between items-center">
                <div>
                  <TextField
                  :inputId="i + 'taxrateinputid'"
                  :inputext="tax.taxValue"
                  :placholderText="`Tax rate`"
                  :lableText="'Tax Rate %'"
                  :inputType="'number'"
                  :textRight="false"
                  :autoFocus="false"
                  :setReadOnly="false"
                  :fieldError="tax.valueErr !== ''"
                  @keyPressAction="tax.valueErr = ''"
                  @inputChangeAction="(data) => tax.taxValue = data" />
                  <p v-if="tax.valueErr !== ''" class="text-error heading-7">{{tax.valueErr}}</p>
                </div>
                <div >
                  <span v-if="taxlist.length > 1" @click="deleteTax(i)" class="text-error cursor-pointer heading-3">
                  <i class="fa-regular fa-trash-can"></i>
                  </span>
                </div>
              </div>
            <div v-if="i < taxlist.length - 1" class="divider my-4"></div>
          </div>
          <div v-if="taxId === 0">
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add More Tax'" @buttonAction="addMoreTax()"/>
          </div>
        </div>
      </div>
      <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItemsTax()"/>
        </div>
      </div>
    </div>
  </div>
  <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure delete tax?'" />
  <div v-if="linkedTaxAlert" class="popup_overlay relative px-4">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-5 font-semibold">
            <p class="heading-4 text-text2">This tax is also linked with other items. If you delete this item it will be removed from all other linked items!</p>
            <div class="my-1 text-gray4 heading-6" v-if="taxListing.length > 0">Linked Items List:</div>
              <li class="text-text2 hover:text-primary group" v-for="(tax, i) in taxListing" :key="i" >
                <span class="cursor-pointer" @click="redirectTaxDet(tax)">{{ tax.itemName }}</span>
              </li>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="linkedTaxAlert = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="removeTax"/>
          </div>
      </div>
    </div>
  <!-- <ConfirmBox :cancelbtnText="'Cancel'"  :confirmBtnText="'Delete'" v-if="linkedTaxAlert" :title="`This tax is also linked with other items.
  If you delete this item it will be removed from all other linked items!`" /> -->
</div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['taxId', 'taxDetail'],
components: {
  Button,
  TextField,
  ConfirmBox,
},
data () {
  return {
    taxListing: [],
    removeConfirm: false,
    linkedTaxAlert: false,
    taxlist: [
      {
        taxId: 0,
        taxName: '',
        taxValue: '',
        isActive: true,
        nameErr: '',
        valueErr: ''
      }
    ],
  }
},
 created() {
},
mounted () {
  this.$root.$on('confirmBoxHandler', (response) => {
    document.body.style = 'overflow: visible;'
    if (response) {
        this.deleteApi()
      }
      this.removeConfirm = false
    this.linkedTaxAlert = false
  })
  if (this.taxId > 0) {
    if (this.taxDetail !== null) {
      this.taxlist = []
      this.taxlist.push({
        taxId: this.taxDetail.taxId,
        taxName: this.taxDetail.taxName,
        taxValue: this.taxDetail.taxValue,
        isActive: true,
        nameErr: '',
        valueErr: ''
      })
    }
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  redirectTaxDet (tax) {
    let routeData1 = this.$router.resolve({name: 'SettingItemDetails', params: {itemId: tax.itemMasterId}})
    window.open(routeData1.href, '_blank')
  },
  removeTax () {
    document.body.style = 'overflow: visible;'
    this.deleteApi()
    this.removeConfirm = false
    this.linkedTaxAlert = false
  },
  bluNumberset (data, index) {
      console.log('cccc', data === '');
      if (data === '') {
          this.taxlist[index].taxValue = 0
      }
  },
  deleteTax (index) {
      this.taxlist.splice(index,1)
  },
  addMoreTax () {
    this.taxlist.push({
      taxId: 0,
      taxName: '',
      taxValue: '',
      isActive: true,
      nameErr: '',
      valueErr: ''
    })
  },
  discard () {
      document.body.style = 'overflow: visible;'
    this.$root.$emit('addNewTaxComponennt')
  },
  saveItemsTax () {
    if (this.taxId === 0) {
      let isValid = true
      let finalArr = []
      this.taxlist.forEach((el) => {
        el.taxValue = Number(el.taxValue)
        if (el.taxName.trim() === '') {
            isValid = false
            el.nameErr = 'Tax name is requied.'
        }
        if (el.taxValue <= 0) {
            isValid = false
            el.valueErr = 'Tax rate is requied.'
        }
        finalArr.push(
            {
              taxId: el.taxId,
              taxName: el.taxName,
              taxValue: el.taxValue,
              isActive: el.isActive,
            }
        )
      })
      if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.addNewTax(
          finalArr,
          response => {
            this.$root.$emit('addNewTaxComponennt', true, response.Data)
            document.body.style = 'overflow: visible;'
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    } else {
      let isValid = true
      if (this.taxlist[0].taxName.trim() === '') {
        isValid = false
        this.taxlist[0].nameErr = 'Tax name is requied.'
      }
      if (this.taxlist[0].taxValue <= 0) {
        isValid = false
        this.taxlist[0].valueErr = 'Tax rate is requied.'
      }
      if (isValid) {
        let sendObj = {
          taxId: this.taxlist[0].taxId,
          taxName :this.taxlist[0].taxName,
          taxValue : parseFloat(this.taxlist[0].taxValue),
          isActive : true
        }
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.UpdateTax(
          sendObj,
          response => {
            this.$root.$emit('addNewTaxComponennt', true, response.Data)
            document.body.style = 'overflow: visible;'
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    }
  },
  deleteApi () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.SettingDeleteTax(
      this.taxId,
      response => {
        console.log('response', response);
        this.$root.$emit('addNewTaxComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  checkLInkCall () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.checkTaxLink(
      this.taxId,
      response => {
        if (response.Data !== null) {
          this.taxListing = response.Data !== null ? response.Data : []
          this.linkedTaxAlert = true
        } else {
          this.removeConfirm = true
        }
        console.log('response', response);
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  removeConfirmCheck () {
    this.checkLInkCall()
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
  // this.$root.$off('closeConfirmBoxDialog')
}
}
</script>
<style scoped>

</style>