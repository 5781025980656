<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Tax ({{totalCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Tax...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Tax'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="categoryList.length > 0">
          <div v-for="(data, index) in categoryList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.taxId, data)">
            <div class="flex items-center justify-between">
              <p class="text-gray4 heading-5">{{data.taxName}}</p>
              <p class="text-gray4 heading-5 pr-2" v-if="data.taxValue !== ''">{{data.taxValue}} %</p>
            </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div> 
          <div>
            <Pagination :totalDataCount="totalCount" :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getLeadDataFromPagination" />
          </div>
      </div>
    </div>
    <add-edit-tax v-if="addTax" :taxId="selectedCatId" :taxDetail="selectedDetail" />
   </div>
</template>
<script>
import Pagination from '@/View/components/pagination.vue'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import AddEditTax from './addEditTax.vue'
export default {
  components: {
    SearchTextInput,
    NoRecords,
    Button,
    AddEditTax,
    Pagination,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Email', icon: '', sortName: 'emailAddress'},
        {title: 'Contact', icon: '', sortName: 'phoneNumber'},
        {title: 'Added Date', icon: 'DESC', sortName: 'addedDate'},
        {title: 'Status', icon: '', sortName: 'statusName'}
      ],
      categoryList: [],
      searchForLead: '',
      getLeadListDebounce: null,
      resetPaginationValues: false,
      addTax: false,
      totalCount: 0,
      selectedCatId: 0,
      selectedDetail: {},
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'addedDate',
        statusList: []
      },
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.getLeadList()
     this.getLeadListDebounce = this.debounce(function () {
      this.getLeadList()
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addNewTaxComponennt", (response) => {
      console.log(response);
      if (response) {
        this.getLeadList()
        this.addTax = false
      }
      this.addTax = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
    this.$root.$off('addNewTaxComponennt')
  },
  methods: {
    openDetail (Id, data) {
      this.selectedCatId = Id
      this.selectedDetail = data
      this.addTax= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.selectedDetail = {}
      this.addTax= true
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getLeadList(true)
    },
    searchCall (data) {
      this.searchForLead = data
      this.getLeadListDebounce()
    },
    getLeadList (showlistLoader) {
      if (showlistLoader && this.searchForLead !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForLead === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.SettingTaxListing(
          this.filterObject.start,
          this.filterObject.length,
          this.searchForLead,
          '',
          '',
          response => {
            this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.totalCount = response.Data.count
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>